<template>
  <div class="circle-index">
      <div style="width: 1500px;margin: 108px auto 0;">
      <div style="width: 100%; overflow: hidden">
        <div style="width: 280px; position: fixed; top: 108px;">
          <div
              style="width: 100%;margin-bottom: 10px;height: 44px; background: #fff; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); position: relative">
            <div
                style="position:absolute;top: 0;left: 0;width: 0;height: 0;border: 14px solid #fff;border-top-color: #031F88;border-left-color: #031F88;border-bottom-color:transparent ;border-right-color: transparent;"></div>
            <div style="width: 100%;display: flex; color: #031F88" class="hand op sc" @click="toMySpace">
              <img style="vertical-align: middle;border-radius: 50px;width: 32px;height: 32px; margin: 4px 10px 0 40px"
                   :src="user.headIco"/>
              <div
                  style="vertical-align: middle;font-weight: 600; line-height: 32px; font-size: 16px; margin-top: 6px; margin-right: 12px">
                进入<span v-if="user.userType === 1">个人</span><span v-if="user.userType === 2">机构</span><span v-if="user.userType === 3">账号</span>空间
              </div>
              <i style="font-size: 20px; margin-top: 13px; color: #031F88" class="el-icon-caret-right"></i>
            </div>
          </div>
          <div
              style="width: 92%;margin-bottom: 1px;padding: 8px 4%;background: #fff; overflow: hidden; font-weight: 600; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
            <el-menu :default-active="show_name" style="width:100%; overflow: hidden;" @select="checkoutRecommend">
              <el-menu-item index="home" style="height: 46px; line-height: 42px; margin-bottom: 2px">
                <template>
                  <div style="display: flex">
                    <div style="width: 30px; height: 30px; margin-left: 10px; margin-right: 20px;">
                      <img src="@/assets/imgs/circle/home.png"/>
                    </div>
                    <span style="font-size: 16px; margin-top: 2px">朋友圈首页</span>
                  </div>
                </template>
              </el-menu-item>
              <el-menu-item index="recommend" style="height: 46px; line-height: 42px; margin-bottom: 2px">
                <template>
                  <div style="display: flex">
                    <div style="width: 30px; height: 30px; margin-left: 10px; margin-right: 20px;">
                      <img src="@/assets/imgs/circle/recommend.png"/>
                    </div>
                    <span style="font-size: 16px; margin-top: 2px">热门推荐</span>
                  </div>
                </template>
              </el-menu-item>
              <el-menu-item index="activity" style="height: 46px; line-height: 42px; margin-bottom: 2px">
                <template>
                  <div style="display: flex">
                    <div style="width: 30px; height: 30px; margin-left: 10px; margin-right: 20px;">
                      <img src="@/assets/imgs/circle/activity.png"/>
                    </div>
                    <span style="font-size: 16px; margin-top: 2px">报名活动</span>
                  </div>
                </template>
              </el-menu-item>
              <el-menu-item index="favorite" style="height: 46px; line-height: 42px; margin-bottom: 2px">
                <template>
                  <div style="display: flex">
                    <div style="width: 30px; height: 30px; margin-left: 8px; margin-right: 20px;">
                      <img src="@/assets/imgs/circle/favorites.png"/>
                    </div>
                    <span style="font-size: 16px; margin-top: 2px">我的收藏夹</span>
                  </div>
                </template>
              </el-menu-item>
              <el-menu-item index="notice" style="height: 46px; line-height: 42px; margin-bottom: 2px">
                <template>
                  <div style="display: flex">
                    <div style="width: 30px; height: 30px; margin-left: 8px; margin-right: 20px;">
                      <img src="@/assets/imgs/circle/notice.png"/>
                    </div>
                    <span style="font-size: 16px; margin-top: 2px">通知消息</span>
                    <el-badge :value="notice" style="margin-left: 60px;" v-if="notice > 0"></el-badge>
                  </div>
                </template>
              </el-menu-item>
              <el-menu-item index="message" style="height: 46px; line-height: 42px; margin-bottom: 2px">
                <template>
                  <div style="display: flex">
                    <div style="width: 30px; height: 30px; margin-left: 8px; margin-right: 20px;">
                      <img src="@/assets/imgs/circle/message.png"/>
                    </div>
                    <span style="font-size: 16px; margin-top: 2px">朋友圈私信</span>
                    <el-badge :value="message" style="margin-left: 45px;" v-if="message > 0"></el-badge>
                  </div>
                </template>
              </el-menu-item>

              <el-menu-item index="friend" style="height: 46px; line-height: 42px;">
                <template>
                  <div style="display: flex">
                    <div style="width: 30px; height: 30px; margin-left: 8px; margin-right: 18px;">
                      <img src="@/assets/imgs/circle/friends.png"/>
                    </div>
                    <span style="font-size: 16px">好友分组</span>
                  </div>
                </template>
              </el-menu-item>

              <el-menu-item style="height: 46px; line-height: 42px; margin-bottom: 2px" index="organ"
                            v-if="user.userType === 3 || user.userType === 2">
                <template>
                  <div style="display: flex">
                    <div style="width: 30px; height: 30px; margin-left: 8px; margin-right: 18px;">
                      <img src="@/assets/imgs/circle/organList.png"/>
                    </div>
                    <span style="font-size: 16px">机构通讯录</span>
                  </div>
                </template>
              </el-menu-item>
            </el-menu>
          </div>
          <div class="shadow"
               style="width:100%;overflow:hidden; height:auto;background-color: #FFFFFF; margin: 4px 0; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
            <div style="width:100%; height: 42px;line-height:40px;font-weight: bold;overflow: hidden;">
              <div
                  style="color:#999999;text-align: center;width: calc(100%/2 - 2px);float: left;box-sizing: border-box;letter-spacing: 2px;cursor: pointer;"
                  :style="tagType===0?'color:#031F88;box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);transform: scale(1.1)':''"
                  @click=screenTagList(0)>关注店铺列表
              </div>
              <div
                  style="color:#999999;text-align: center;width: calc(100%/2 - 2px);float: left;box-sizing: border-box;letter-spacing: 2px;padding-right: 5px;cursor: pointer;"
                  :style="tagType===1?'color:#031F88;box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);transform: scale(1.1)':''"
                  @click=screenTagList(1)>关注环境页
              </div>
            </div>
          </div>
          <div class="shop-recommend"
               style="width: 100%;margin: 2px 0 20px 0;padding: 18px 0;background: #fff; height: 340px; overflow: auto; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
            <div v-for="(highQualityShop,highQualityShopIndex) in followShop" :key="highQualityShopIndex"
                 class="hand op" style="margin-bottom: 20px; margin-left: 10px; display: flex"
                 @click="$router.push({path:'/shop/shopCode',query:{id:highQualityShop.id}})">
              <div style="width: 47px;height: 47px;">
                <img width="100%;" height="100%" style="border: 1px #999999 solid" :src="highQualityShop.logo.url">
              </div>
              <div>
                <div style="width: 160px;font-size: 16px; margin: 2px 8px">{{ highQualityShop.name }}</div>
                <div style="display: flex; margin: 2px 8px">
                  <div
                      style="width: 18px;height: 18px;background: #2970FF;border-radius: 2px 2px 2px 2px;margin-right: 6px">
                    <div v-if="highQualityShop.type == 1"
                         style="width: 18px;height: 20px;color: #FFFFFF;text-align: center">个
                    </div>
                    <div v-if="highQualityShop.type == 2"
                         style="width: 18px;height: 20px;color: #FFFFFF;text-align: center">企
                    </div>
                  </div>
                  <div
                      style="width: 58px;height: 18px;background: #FF9800;border-radius: 2px 2px 2px 2px;margin-right: 6px">
                    <div style="height: 14px;font-size: 12px;color: #FFFFFF;">10年老店</div>
                  </div>
                  <div style="color: #999999;width: auto;height: 16px;font-size: 14px;">所在地：<span
                      style="color: #666666">{{ highQualityShop.city.province }}</span></div>
                </div>
              </div>
            </div>
            <!--                            <div style="display: flex; margin: 10px 0;">-->
            <!--                                <img src="" style="width: 44px; height: 44px; margin: 0 8px 0 18px;"/>-->
            <!--                                <div style="width: 112px; height: 35px; font: normal 400 14px 'FZLanTingHeiS-DB-GB-Regular'; line-height: 16px; font-weight: 400;">新疆新土地城乡规划设计院</div>-->
            <!--                            </div>-->
          </div>
        </div>

        <div v-if="show_name == 'home'" style="margin-left: 280px">
          <div style="width: 900px;float:left;margin: 0 10px;" ref="goTop">
            <div style="margin-bottom: 10px">
              <el-input placeholder="请输入内容" v-model="searchContent" class="input-with-select">
                <div class="hand op sc" style="width: 40px; text-align: center" slot="append"
                     @click="searchContentData">
                  <img style="width: 18px; height: 18px;" src="@/assets/imgs/circle/searchIcon.png">
                </div>
              </el-input>
            </div>
            <div
                style="width: 99%; padding: 4px; margin-bottom: 10px; background-color: #fff; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
              <Release :all_type_change="true"></Release>
            </div>
            <div
                style="width: 100%; overflow: hidden; margin-bottom: 10px; background-color: white;  box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
              <div class="tab border_bottom up" v-for="(item, index) in personalMenu" :key="index"
                   @click="indexTags = item.key">
                <label>
                  <div class="head_white hand">
                    <div class="head_white_word" :style="indexTags === item.key ? 'color: #2970FF' : ''"
                         @click="clickNavigation(item.key)">{{ item.value }}
                    </div>
                  </div>
                </label>
              </div>
            </div>

            <div v-if="content_list.length === 0" style="width: 100%; overflow:hidden;">
              <NullBox data="发布内容"></NullBox>
            </div>

            <div v-else>
              <div style="width: 100%; overflow:hidden; ">
                <div v-for="(item,index) in content_list" v-bind:key="item.id">
                  <ContentALL :data="item" @deleteContent="deleteContent" :index="index"></ContentALL>
                </div>
              </div>

              <p v-if="loading">加载中...</p>
            </div>

          </div>
          <div style="width: 300px;float:left;">
            <div
                style="width: 100%; min-height: 426px; background: #fff;margin-bottom: 10px;overflow: hidden;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
              <HotTopic></HotTopic>
            </div>

            <div
                style="width: 100%; min-height: 400px; background: #ffffff; float: left; margin-bottom: 10px; overflow: hidden; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);" v-if="is_login">
              <div style="width: calc(100% - 20px); padding: 0 10px; background: #FFFFFF;">
                <div class="module_title">
                  <div class="industry_title_text">推荐关注</div>
                  <img class="hand op sc" style="width: 76px; height: 24px; margin-top: 16px;" @click="changeAttention"
                       src="@/assets/imgs/circle/change.png">
                </div>
              </div>
              <div v-if="user.authenticationStatus === 2">
                <div style="width: 100%;height: 60px; margin-bottom: 6px; margin-left: 16px"
                     v-for="(item,index) in interest_list" :key="index">
                  <div>
                    <div style="float: left;width: 60px;height: 60px;" class="hand op"
                         @click="openInfo(item.uuid, item.userType)">
                      <img style="width: 45px;height: 45px;border-radius: 50%;margin-top: 7px;margin-left: 7px;"
                           :src="item.headIco +'-200x200'">
                    </div>
                    <div style="float: left;width: 140px;height: 60px; cursor: pointer;"
                         @click="openInfo(item.uuid, item.userType)">
                      <div
                          style="width: 140px;height: 20px;font-weight: bold;line-height: 20px;margin-top: 10px;text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                        {{ item.nickName }}
                      </div>
                      <div
                          style="width: 140px;height: 20px;font-size: 12px; line-height: 20px; color: #c1c1c1; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                        <span v-if="item.address.cityName">{{ item.address.cityName[1] }}</span><span
                          v-if="item.major && item.address.cityName"> | </span>{{ item.major }}
                      </div>
                    </div>
                    <div style="float: left">
                      <div v-if="item.isFans === 0" class="hand pay-btn" @click="toPayFriends(item.uuid)">关注</div>
                      <div v-if="item.isFans === 1" class="hand friend-btn" @click="toAddFriends(item.uuid)">加好友</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div style="padding: 0 70px; font-size: 16px; margin-top: 120px">
                  请完成<span style="color: #1C7EFF; font-weight: 600" class="hand op" @click="toAuth">专业认证</span>，以便平台为您推荐优质用户
                </div>
              </div>
            </div>

            <div style="width: 100%; min-height: 480px; background: #fff; overflow: hidden; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
              <HotArticle></HotArticle>
            </div>

          </div>
        </div>

        <div v-if="show_name == 'recommend'" style="margin-left: 280px">
          <div style="width: 900px;float:left;margin: 0 10px;">
            <Recommend></Recommend>
          </div>
          <div style="width: 300px; float:left;">
            <div
                style="width: 100%;min-height: 420px; background: #fff;margin-bottom: 10px;overflow: hidden;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
              <HotTopic></HotTopic>
            </div>

            <div style="width: 100%; min-height: 400px; background: #ffffff; float: left; margin-bottom: 10px; overflow: hidden; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);" v-if="is_login">
              <div style="width: calc(100% - 20px); padding: 0 10px; background: #FFFFFF;">
                <div class="module_title">
                  <div class="industry_title_text">推荐关注</div>
                  <img class="hand op sc" style="width: 76px; height: 24px; margin-top: 16px;" @click="changeAttention"
                       src="@/assets/imgs/circle/change.png">
                </div>
              </div>
              <div v-if="user.authenticationStatus === 2">
                <div style="width: 100%;height: 60px; margin-bottom: 6px; margin-left: 16px" v-for="(item,index) in interest_list" :key="index">
                <div>
                  <div style="float: left;width: 60px;height: 60px;" class="hand op" @click="openInfo(item.uuid, item.userType)">
                    <img style="width: 45px;height: 45px;border-radius: 50%;margin-top: 7px;margin-left: 7px;" :src="item.headIco +'-200x200'">
                  </div>
                  <div style="float: left;width: 140px;height: 60px; cursor: pointer;" @click="openInfo(item.uuid, item.userType)">
                    <div style="width: 140px;height: 20px;font-weight: bold;line-height: 20px;margin-top: 10px;text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                      {{ item.nickName }}
                    </div>
                    <div style="width: 140px;height: 20px;font-size: 12px; line-height: 20px; color: #c1c1c1; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                      <span v-if="item.address.cityName">{{ item.address.cityName[1] }}</span>
                      <span v-if="item.major && item.address.cityName"> | </span>{{ item.major }}
                    </div>
                  </div>
                  <div style="float: left">
                    <div v-if="item.isFans === 0" class="hand pay-btn" @click="toPayFriends(item.uuid)">关注</div>
                    <div v-if="item.isFans === 1" class="hand friend-btn" @click="toAddFriends(item.uuid)">加好友</div>
                  </div>
                </div>
              </div>
              </div>
              <div v-else>
                <div style="padding: 0 70px; font-size: 16px; margin-top: 120px">
                  请完成<span style="color: #1C7EFF; font-weight: 600" class="hand op" @click="toAuth">专业认证</span>，以便平台为您推荐优质用户
                </div>
              </div>

            </div>

            <div  style="width: 100%; min-height: 480px; background: #fff; overflow: hidden; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
              <HotArticle></HotArticle>
            </div>

          </div>
        </div>

        <div v-if="show_name == 'activity'" style="margin-left: 290px;width: 1210px;float:left; min-height: 100vh">
          <Activity></Activity>
        </div>

        <div v-if="show_name == 'notice'" style="margin-left: 290px;width: 1210px;float:left;min-height: 100vh">
          <Notice @getFriendGroups="getFriendGroups"></Notice>
        </div>

        <div v-if="show_name == 'message'" style="margin-left: 290px;width: 1210px;float:left;min-height: 100vh">
          <Message></Message>
        </div>

        <div v-if="show_name == 'favorite'" style="margin-left: 290px;width: 1210px;float:left;min-height: 100vh">
          <Favorite></Favorite>
        </div>

        <div v-if="show_name == 'friend'" style="margin-left: 290px;width: 1210px;float:left;">
          <div style="width: 300px; position: fixed; float: left; height: calc(100vh - 108px); background: #fff; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
            <FriendsGroup @showFriendInfo="changeFriendInfo" :type="1"></FriendsGroup>
          </div>
          <FriendPreview :key="show_name" :userId="friendUuid" style="width: 900px; margin-left: 300px; min-height: 100vh"></FriendPreview>
        </div>

        <div v-if="show_name == 'organ'" style="margin-left: 290px;width: 1210px;float:left;">
          <div style="width: 300px; position: fixed; float: left; height: calc(100vh - 108px); background: #fff; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);" :key="2">
            <FriendsGroup @showFriendInfo="getLookList" :type="2"></FriendsGroup>
          </div>
          <FriendPreview :key="show_name" :userId="friendUuid" :isOrgan="true" style="width: 900px; margin-left: 300px; min-height: 100vh"></FriendPreview>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Release from "./components/Release";
import Recommend from "./recommend";
import Activity from "./activity";
import Notice from "./notice";
import Message from "./message";
import Favorite from "./favorite";
import ContentALL from "./components/ContentAll";
import FriendPreview from "./friendPreview";
import HotTopic from "./components/hot/HotTopic";
import HotArticle from "./components/hot/HotArticle";
import NullBox from "@/pages/circle/components/NullBox.vue";
import FriendsGroup from "./components/FriendsGroup";
import MomentsLandingPage from "@/pages/index/momentsLandingPage.vue";
import newApi from "@/utils/newApi";

export default {
  name: "",
  components: {
    MomentsLandingPage,
    FriendsGroup,
    NullBox,
    HotArticle,
    HotTopic,
    FriendPreview,
    ContentALL,
    Favorite,
    Message,
    Notice,
    Activity,
    Release,
    Recommend
  },
  data() {
    return {
      showBook: false,
      loading: true,
      friendUuid: '',
      userInfo: {},
      show_name: 'home',
      like: '',
      text: '',

      page: 1,
      pageSize: 10,
      more: 1,
      original_list: [],
      content_list: [],
      images_list: [],
      videos_list: [],
      enclosure_list: [],
      article_list: [],
      show_skeleton: false,

      // 推荐好友
      interest_list: [],
      recommendPage: 1,
      friends: true,
      experts: true,
      unknown: true,

      // 关注的店铺
      followShop: [],

      // 标签
      indexTags: 'all',

      // 搜索词
      searchContent: '',
      artStyle: true,

      personalMenu: [
        {
          key: 'all',
          value: '全部'
        },
        {
          key: 'dynamic',
          value: '动态'
        },
        {
          key: 'images',
          value: '图片'
        },
        {
          key: 'videos',
          value: '视频'
        },
        {
          key: 'enclosure',
          value: '附件'
        },
        {
          key: 'article',
          value: '文章'
        },
      ],

      selectList: ['home', 'recommend', 'activity', 'notice', 'friend', 'message', 'favorite'],
      isPay: false,

      hotArticleList: [],
      tagType: 0,

      organOr: ''  // 机构通讯录或好友分组

    }
  },

  computed: {
    user() {
      return this.$store.state.user
    },
    is_login() {
      return this.$store.state.is_login
    },
    router() {
      return this.$store.state.router
    },
    message() {
      return this.$store.state.msgCount
    },
    notice: {
      get(){
        return this.$store.state.noticeCount
      },
      set(value) {
        this.$store.state.noticeCount = value
        return value
      }
    }
  },
  watch: {
    // is_login(val) {
    //   if (val) {
    //     this.getInspirationList();  //获取动态
    //     this.getRecommendFriendList();
    //     this.getFollowShopList(); // 我关注的店铺
	  //   this.getNoticeNoReadNum();
		// this.getMsgNoReadNum();
    //   }
    // },

    // 监听路由参数的变化
    '$route.query.show_name': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.show_name = newVal
        }
      }
    }
  },
  props: {},

  created() {
	  var that = this;
	    if (this.$route.query.show_name) {
	      this.show_name = this.$route.query.show_name
	    }
		if(this.is_login){
			this.getNoticeNoReadNum();
			this.getMsgNoReadNum();
		}

  },

  updated() {
    var that = this;
    window.onscroll = function () {
      if (that.show_name == 'home') {
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        //变量windowHeight是可视区的高度
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollHeight - scrollTop - windowHeight <= 0) {
          if (that.more == 1 && that.loading === false) {
            var type = 0;
            switch (that.indexTags) {
              case 'all':
                type = 0;
                break;
              case 'dynamic':
                type = 1;
                break;
              case 'article':
                type = 2;
                break;
              case 'images':
                type = 3;
                break;
              case 'videos':
                type = 4;
                break;
              case 'enclosure':
                type = 5;
                break;
            }
            that.load();
            if (that.searchContent) {
              that.getSearchContent()
            } else {
              that.getInspirationList(type);
            }

          } else {
            console.log("已加载完");
          }
        }
      }
    }
  },

  mounted() {
      var that = this;
    //接收事件
    this.$EventBus.$on("changeData", (data) => {
      this.$nextTick(() => {
        this.page = 1;
        this.more = 0;
        this.content_list = [];
        var type = 0;
        switch (this.indexTags) {
          case 'all':
            type = 0;
            break;
          case 'dynamic':
            type = 1;
            break;
          case 'article':
            type = 2;
            break;
          case 'images':
            type = 3;
            break;
          case 'videos':
            type = 4;
            break;
          case 'enclosure':
            type = 5;
            break;
        }
        this.load();
        this.getInspirationList(type);
      });
    });
    this.getInspirationList();  //获取动态
    if (this.is_login) {
      this.getRecommendFriendList();
      this.getFollowShopList(); // 我关注的店铺
      // this.getFriendCircleBanner(); // 获取圈子banner
    }
  },

  methods: {
    // 去认证
    toAuth() {
      if (!this.utils.toAuth(2)) {
        return false
      }
    },

	  getMsgNoReadNum(){
		  let that = this;
		  this.newApi.getMsgNoReadNum({}).then((Msg)=>{
			  that.$store.state.msgCount = Msg.data;

		  });
	  },
	  getNoticeNoReadNum(){
		  let that = this;
		  this.newApi.getNoticeNoReadNum({}).then((Notice)=>{
			  that.notice = Notice.data;
			  that.$store.state.noticeCount = Notice.data;
		  });
	  },
    // 重新获取通知数量
    updateCount() {
	    this.getNoticeNoReadNum();
    },

    // 搜索内容
    searchContentData() {
      this.page = 1;
      this.more = 0;
      this.indexTags = 'all';
      this.content_list = [];
      if (this.searchContent) {
        this.getSearchContent()
      } else {
        this.getInspirationList()
      }
    },

    // 获取搜索内容
    getSearchContent(type = 0, load = true) {
      let that = this;
      let params = {};
      params.pageNo = this.page;
      params.pageSize = this.pageSize;
      params.content = this.searchContent;
      that.loading = load;
      if (that.loading) {
        this.newApi.circleSearch(params).then(res => {
          for (const item of res.data.data) {
            that.original_list.push(item);
            that.content_list.push(item);
            that.more = 1;
          }
          if (res.data.data.length < 10) {
            that.more = 0;
          } else {
            that.page++;
          }
          that.loading = false
        })
      }
    },

    // 删除内容更新
    deleteContent(e) {
      this.content_list.splice(e, 1)
    },

    // 切换用户个人空间
    changeFriendInfo(uuid) {
      this.show_name = 'friend';
      this.friendUuid = uuid
    },

    // 子组件查看更多
    toMore(name) {
      this.show_name = name
    },

    // 切换店铺和环境页
    screenTagList(type) {
      if (!this.utils.toAuth(1)) {
        return false
      }
      this.tagType = type;
      this.followShop = [];
      if (type === 0) {
        this.getFollowShopList();
      }
    },

    // 获取我关注的店铺
    getFollowShopList() {
      this.newApi.getMyCollectShop().then(res => {
        this.followShop = res.data
      })
    },

    // 切换通讯录列表
    getLookList(uuid) {
      this.show_name = 'organ';
      this.friendUuid = uuid
    },

    load() {
      this.loading = true;
    },

    // 点击发布动态回到顶部
    goTopContent() {
      this.show_name = 'home';
      this.$refs.goTop.scrollTop = 0
    },

    // 获取好友分组
    getFriendGroups() {
      this.newApi.getMyFriendGroupList({}).then(res => {
        this.groups = res.data
      })
    },

    // 点击关注
    toPayFriends(e) {
      var that = this;
      if (!this.utils.toAuth(1)) {
        return false
      }
        that.newApi.followAccount({uuid: e}).then(res => {
          if (res.isSuccess == 1) {
            that.utils.sus(res.data);
            if (that.recommendPage > 1) {
              that.recommendPage = that.recommendPage - 1;
            }
            that.getRecommendFriendList()
          }
        });
    },

    // 点击加好友
    toAddFriends(id) {
      if (this.user.authenticationStatus !== 2 && this.user.userType === 1) {
        store.commit('setShowAuthPopup', 1);
        return false;
      } else {
        this.newApi.addFriends({uuid: id}).then(res => {
          if (res.isSuccess == 1) {
            this.utils.sus(res.data);
            if (this.recommendPage > 1) {
              this.recommendPage = this.recommendPage - 1;
            }
            this.getRecommendFriendList()
          }
        })
      }
    },

    // 推荐关注换一换
    changeAttention() {
      this.getRecommendFriendList()
    },

    // 转发后获取动态
    getForwardContent() {
      this.page = 1;
      this.more = 1;
      this.content_list = [];
      this.clickNavigation(this.indexTags)
    },

    // 获取动态
    clickNavigation(str) {
      var that = this;
      var type = 0;
      that.searchContent = '';   // 暂定（等接口传参）
      that.indexTags = str;
      that.page = 1;
      that.more = 0;
      that.content_list = [];
      switch (str) {
        case 'all':
          type = 0;
          break;
        case 'dynamic':
          type = 1;
          break;
        case 'article':
          type = 2;
          break;
        case 'images':
          type = 3;
          break;
        case 'videos':
          type = 4;
          break;
        case 'enclosure':
          type = 5;
          break;
      }
      that.getInspirationList(type, true);
    },

    getInspirationList(type = 0, load = true) {
      var that = this;
      that.loading = load
      if (that.loading) {
        this.newApi.getInspirationList({type: type, page: that.page, pageSize: that.pageSize}).then((res) => {
          for (const item of res.data) {
            that.original_list.push(item);
            that.content_list.push(item);
            that.more = 1;
          }
          if (res.data.length < 10) {
            that.more = 0;
          } else {
            that.page++;
          }
          that.loading = false
        })
      }
    },

    // 获取推荐好友列表
    getRecommendFriendList() {
      this.newApi.getRecommendFriends({pageSize: 5, page: this.recommendPage}).then(res => {
        if (res.isSuccess == 1) {
          if (res.data.length !== 0) {
            if (res.data.length < 5) {
              this.interest_list = res.data;
              this.recommendPage = 1
            } else {
              this.interest_list = res.data;
              this.recommendPage++
            }
          } else {
            this.recommendPage = 1
          }
        }
      })
    },

    checkoutRecommend(e) {
      if (e == 'message') {
        this.$store.state.msgCount = 0;
        const routeData = this.$router.resolve({name: 'News'})
        window.open(routeData.href, '_blank');
      } else {
        if (e==='activity' || e==='favorite' || e==='notice' || e==='friend') {
          if (!this.utils.toAuth(0)) {
            return false
          }
        }
        this.show_name = e
        this.friendUuid = ''
        this.$router.push({path: '/circle/index', query: {show_name: e}})
      }
    },


    // 点击关注打开用户信息
    openInfo(uuid) {
      if (!this.utils.toAuth(1)) {
        return false
      }
      this.utils.a("/circle/mySpace/" + uuid)
    },

    // 点击进入个人空间
    toMySpace() {
      if (!this.utils.toAuth(0)) {
        return false
      }
      if (this.user.userType === 1) {
        this.utils.a("/circle/mySpace/" + this.user.uuid)
      }
      if (this.user.userType === 2) {
        this.utils.a('/circle/organSpace/' + this.user.uuid)
      }
      if (this.user.userType === 3) {
        this.utils.a('/circle/organSpace/' + this.user.uuid)
      }
    },
  },

  beforeMount() {
    // this.get_content_list()
  }
}
</script>
<style scoped>
.circle-index {
  font-size: 14px;
  background: #f0f0f0;
}

.pay-btn {
  width: 26px;
  height: 16px;
  font-weight: 600;
  margin: 12px 0 0 12px;
  padding: 4px 12px;
  font-size: 12px;
  border: 2px #FFA500 solid;
  border-radius: 20px;
  color: #FFA500;
}

.friend-btn {
  width: 38px;
  height: 16px;
  font-weight: 600;
  margin: 12px 0 0 12px;
  padding: 4px 6px;
  font-size: 12px;
  border: 2px #2970FF solid;
  border-radius: 20px;
  color: #2970FF;
}

.hand {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.op:active {
  opacity: 0.6;
  -webkit-transition: all .1s;
  transition: all .1s;
  opacity: .6;
}

.op {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all .5s;
  transition: all .5s;
}

.sc:active {
  transform: scale(0.97);
}

.tab {
  text-align: center;
  margin: auto 0;
  float: left;
  cursor: pointer;
}

.tab-search {
  width: 20%;
  float: right;
  margin: 7px 2%;
  cursor: pointer;
}

.up {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.1s;
}

.head_white {
  height: 26px;
  padding: 14px 0;
}

.head_white_word {
  width: 70px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  color: #999999;
  font-size: 17px;
  font-weight: 600;
  margin-left: 20px;
  float: left;
}

.shop-recommend::-webkit-scrollbar {
  width: 0;
}

.circle-index .el-dialog {
  margin-left: 100px;
  margin-top: 40vh !important;
}

.module_title_width_text p {
  width: 8px;
  background: #000;
  height: 20px;
  float: left;
  margin: 15px 10px;
}

/deep/ .input-with-select .el-input__inner {
  height: 44px;
  border-radius: 0;
}

/deep/ .input-with-select .el-input-group__append {
  background-color: #2970FF;
  border: 1px solid #2970FF;
}

.el-menu {
  border: none
}

/deep/ .el-submenu__icon-arrow {
  display: none !important;
}

/deep/ .el-submenu__title {
  height: 42px !important;
  line-height: 42px !important;
}

.is-active {
  background-color: #EEEEEE !important;
  color: #303133 !important;
}

.el-submenu__title:hover {
  background-color: #EEEEEE !important;
}

.el-menu-item:focus, .el-menu-item:hover {
  background-color: #EEEEEE !important;
}

.el-menu-item {
  min-width: 0 !important;
}

.el-input--mini {
  width: 70px !important;
  height: 18px !important;
}

.el-input__inner {
  border-radius: 0;
  padding: 0;
}

/deep/ .el-button {
  padding: 10px 10px !important;
}

.tab {
  text-align: center;
  margin: auto 1%;
}

.module_title_width_text p {
  width: 8px;
  background: #000;
  height: 20px;
  float: left;
  margin: 15px 10px;
}

.inner-container {
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.inner-container::-webkit-scrollbar {
  display: none;
}

.module_title {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
}

.industry_title_text {
  line-height: 50px;
  margin-left: 10px;
  color: #031F88;
  font-size: 18px;
  font-weight: bold;
}
</style>
